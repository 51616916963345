<template>
  <div>
    <div v-if="loading" class="text-center">
      <v-progress-circular indeterminate color="primary"/>
    </div>
    <div class="proposal" v-if="!loading">
      <div :class="wrapperClass">
        <AppBar/>
        <div :class="contentClass">
          <LimitsWarning v-if="isCreating"/>
          <div class="proposal-tabs"></div>
          <Header :image="headerImage"/>
          <div
            v-for="container in availableContainers"
            :class="['container-' + container.container_type]"
            :key="container.id"
          >
            <component
              :is="containerName(container.container_type)"
              :container="container"
              :company="company"
              :proposal="proposal"
            />
          </div>
        </div>
      </div>
      <Slideout
        v-if="$store.state.slideout.visible"
        @complete="completeSlideout"
      />
      <FloatingMenu
        :buttons="floatingButtons"
        :menu="floatingMenu"
      ></FloatingMenu>
      <UpgradeAccount
        v-if="showUpgrade"
        @close="toggleUpgradeAccount"
      ></UpgradeAccount>
      <Instructions
        v-if="showInstructions"
        @close="toggleInstructions"
        :buttons="floatingButtons"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Container from '@/domains/proposals/components/container';
import DetailsContainer from '@/domains/proposals/components/containers/details';
import Header from '@/domains/proposals/components/containers/header';
import FloatingMenu from '@/domains/proposals/components/floating-menu';
import ProposalContainer from '@/domains/proposals/components/containers/proposal';
import Slideout from '@/domains/proposals/components/slideout';
import EVENT from '../../../graphql/Events/Event.gql';
import AppBar from '@/domains/proposals/components/app-bar';
import UpgradeAccount from '@/domains/proposals/components/editors/upgrade-account';
import Instructions from '@/domains/proposals/components/instructions';
import LimitsWarning from '@/domains/proposals/components/limits-warnings';

export default {
  name: 'Proposal',

  props: {
    showSendEmailUi: Boolean,
  },

  components: {
    Container,
    DetailsContainer,
    Header,
    FloatingMenu,
    ProposalContainer,
    Slideout,
    AppBar,
    UpgradeAccount,
    Instructions,
    LimitsWarning,
  },

  data() {
    return {
      showUpgrade: false,
      showInstructions: false,
    };
  },

  computed: {
    availableContainers: function () {
      return this.proposal.tabs[0].containers.filter((container) => {
        return (
          undefined !==
          this.$options.components[this.containerName(container.container_type)]
        );
      });
    },
    contentClass() {
      const classes = [ 'content-inner', 'app-bar-offset' ];
      return classes.join(' ');
    },
    wrapperClass() {
      const classes = [];
      if (this.proposal.proposal_theme) {
        classes.push('theme-' + this.proposal.proposal_theme.slug);
      } else {
        classes.push('theme-default');
      }

      classes.push('paper-size--' + this.company.print_paper_size);
      return classes.join(' ');
    },
    loading() {
      return this.$store.state.proposals.loading;
    },
    company() {
      return this.$store.state.proposals.current.company;
    },
    proposal() {
      return this.$store.state.proposals.current;
    },
    user() {
      return this.$store.state.auth.user;
    },
    hasChanges() {
      return this.$store.state.proposals.currentChanged;
    },
    isCreating() {
      return this.$route.name.includes('Create Proposal');
    },
    createDisabled() {
      return (
        this.isCreating &&
        (1 <= this.$store.state.auth.limits.all_proposals_used ||
          1 <= this.$store.state.auth.limits.total_proposals_used)
      );
    },
    floatingButtons() {
      let buttons = [
        {
          id: 'send',
          class: 'action-floating-menu-send',
          name: 'Publish this proposal',
          icon: 'fa-envelope-o',
          action: this.handlePrimaryActionClick,
          disabled: this.createDisabled,
        },
      ];

      if (this.user) {
        buttons.unshift({
          id: 'save',
          class: 'action-floating-menu-save',
          name: 'Save draft',
          icon: 'fa-floppy-o',
          action: this.save,
          indicator: this.hasChanges,
          disabled: !this.hasChanges || this.createDisabled,
        });
      }

      return buttons;
    },
    floatingMenu() {
      let menu = [
        {
          name: 'Help Center',
          class: 'action-floating-menu-help',
          action: () => {
            window.open(
              'http://help.curate.co/en/collections/2983437-proposal-builder',
              '_blank',
            );
          },
        },
        {
          name: 'Instructions',
          class: 'action-floating-menu-instructions',
          action: this.toggleInstructions,
        },
      ];

      if (this.user) {
        menu.push({
          name: 'Upgrade My Account',
          class: 'action-floating-menu-upgrade',
          action: this.toggleUpgradeAccount,
        });
      }

      return menu;
    },
    header() {
      var header;
      this.proposal.tabs.forEach((tab) => {
        tab.containers.forEach((container) => {
          container.container_type === 'header' ? header = container : '';
        });
      });
      return header;
    },
    headerImage() {
      return !this.header.data.cover ? null : this.header.data.cover.url;
    },
  },

  apollo: {
    proposal: {
      query: EVENT,
      manual: true,
      variables() {
        const event = {
          id: process.env.VUE_APP_DEFAULT_PROPOSAL_TEMPLATE,
          isNew: this.isCreating,
        };
        if (undefined !== this.$route.params.id) {
          event.id = this.$route.params.id;
        } else if (undefined !== this.$route.params.proposal_slug) {
          delete event.id;
          event.proposal_slug = this.$route.params.proposal_slug;
        }

        return { event };
      },
      result({ data, loading }) {
        if (!loading) {
          const event = data.event;

          if (null === event) {
            // Redirect to the home page if the event wasn't found
            this.$router.push({ name: 'Create Proposal' });
            return;
          }

          // If logged in & creating a new proposal set the proposal company to this user's company and owner to this user
          if (
            event.id === process.env.VUE_APP_DEFAULT_PROPOSAL_TEMPLATE &&
            this.user
          ) {
            event.company = { ...this.$store.state.auth.company };
            event.owner = {
              __typename: 'User',
              user_email: this.user.email,
            };
          }
          this.$store.commit('setCurrent', event);
        }
      },
    },
  },

  methods: {
    checkForUnsavedChanges(e) {
      if (this.hasChanges) {
        // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        e.preventDefault();

        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    },
    containerName: (containerType) => {
      if ('' === containerType) {
        return '';
      }

      // Convert snake_case to camelCase
      containerType = containerType.replace(/_[^_]*/g, function (match) {
        return match.charAt(1).toUpperCase() + match.substr(2);
      });

      // Uppercase the first letter
      return (
        containerType.charAt(0).toUpperCase() +
        containerType.substr(1) +
        'Container'
      );
    },
    completeSlideout(event) {
      if (event === 'account-creator') {
        this.saveAndSend();
      }
    },
    save() {
      this.$store
        .dispatch('save')
        .then(({ data, isCreating }) => {
          if (isCreating) {
            const currentSlug = window.location.host.split('.')[0];
            const slug = this.$store.state.auth.company.slug;
            const newHost = window.location.host.replace(currentSlug, slug);
            window.location.href = `https://${newHost}/proposals/${data.data.createdEventId}`;
          }
        })
        .catch((err) => {
          let message =
            err?.graphQLErrors?.[0]?.message ||
            'There was an error saving your proposal.';
          this.$store.commit('notify', {
            message: message,
            timeout: 5000,
            color: 'error',
          });
        });
    },
    saveAndSend() {
      this.save();

      this.$store.dispatch('open', {
        title: 'Send Proposal Email',
        subtitle:
          'Clicking send will save your work in your Curate account and will send the email to your customer',
        saveBtnText: 'Send Email',
        component: 'send-proposal-email-interface',
      });
    },
    handlePrimaryActionClick() {
      if (this.user) {
        this.saveAndSend();
      } else {
        this.$store.dispatch('open', {
          title: 'Send Your Proposal',
          saveBtnText: 'Send Email',
          component: 'signup-and-send',
        });
      }
    },
    toggleUpgradeAccount() {
      this.showUpgrade = !this.showUpgrade;
    },
    setShowInstructions() {
      const isOnboarded = localStorage.getItem('isOnboarded');
      this.showInstructions = this.isCreating && !isOnboarded;
      if (!isOnboarded) localStorage.setItem('isOnboarded', 'true');
    },
    toggleInstructions() {
      this.showInstructions = !this.showInstructions;
    },
  },

  mounted() {
    window.addEventListener('beforeunload', this.checkForUnsavedChanges);

    if (this.showSendEmailUi && this.$store.state.auth.user) {
      this.$store.dispatch('open', {
        title: 'Send Proposal Email',
        saveBtnText: 'Send Email',
        component: 'send-proposal-email-interface',
      });
    }

    this.setShowInstructions();
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.checkForUnsavedChanges);
  },
};
</script>

<style lang="scss">
@import "../sass/variables";
@import "../sass/reboot";
@import "../sass/layout";
@import "../sass/typography";
@import "../sass/mixins/misc";
@import "../sass/themes/sprout/index";

.ProseMirror {
  // Prosemirror puts a `p` tag as the first child of a list item. Overriding this is very difficult, so we do this to remove the default spacing:
  li > p {
    margin-bottom: 0;
  }

  ol,
  ul,
  dl,
  p {
    margin-bottom: 0;
  }

  // Prosemirror injects a `br` at the end of everyline. When a br is already there, this injects an extra line that does not represent what shows up on the actual document. This fixes that
  br + br:last-of-type {
    display: none;
  }
}

.content-inner {
  &.app-bar-offset {
    margin-top: 5rem;
  }
}
</style>
