var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    {
      staticClass: "floating-menu object-floating-menu",
      attrs: { dense: "", rounded: "" }
    },
    [
      _vm._l(_vm.buttons, function(button) {
        return _c(
          "v-tooltip",
          {
            key: button.id + "-floating-button",
            attrs: { bottom: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              class: button.class,
                              attrs: {
                                icon: "",
                                color: "grey darken-3",
                                disabled: button.disabled
                              },
                              on: { click: button.action }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          button.indicator
                            ? _c(
                                "v-badge",
                                {
                                  attrs: {
                                    dot: "",
                                    left: "",
                                    "offset-x": "8",
                                    "offset-y": "8"
                                  }
                                },
                                [_c("v-icon", [_vm._v(_vm._s(button.icon))])],
                                1
                              )
                            : _c("v-icon", [_vm._v(_vm._s(button.icon))])
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          },
          [_c("span", [_vm._v(_vm._s(button.name))])]
        )
      }),
      _vm.showDivider
        ? _c("v-divider", {
            staticClass: "mx-2",
            attrs: { vertical: "", inset: "" }
          })
        : _vm._e(),
      _c(
        "v-menu",
        {
          attrs: { left: "", bottom: "", "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _vm.hasMenu
                    ? _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "action-floating-menu-ellipsis",
                              attrs: { icon: "", color: "grey darken-3" }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("v-icon", [_vm._v("fa-ellipsis-v")])],
                        1
                      )
                    : _vm._e()
                ]
              }
            }
          ])
        },
        [
          _c(
            "v-list",
            _vm._l(_vm.menu, function(item) {
              return _c(
                "v-list-item",
                {
                  key: item.name + "-floating-menu-item",
                  class: item.class,
                  on: { click: item.action }
                },
                [_c("v-list-item-title", [_vm._v(_vm._s(item.name))])],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }