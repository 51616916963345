<template>
  <div class="cu-container--proposal-totals">
    <SlideoutTrigger
      title="Edit Total Info"
      save-button-text="Done"
      component="totals-editor"
    >
      <div class="proposal-total-items">
        <div v-if="!taxInclusive">
          <strong>Items Subtotal: </strong>
          <span>{{ formattedSubtotal }}</span>
        </div>

        <div v-if="!taxInclusive">
          <strong>{{ tax1.name }}: </strong>
          <span>{{ tax1.formattedAmount }}</span>
        </div>

        <div v-if="!taxInclusive && shouldShowTax2">
          <strong>{{ tax2.name }}: </strong>
          <span>{{ tax2.formattedAmount }}</span>
        </div>

        <div>
          <strong>Total: </strong>
          <span>{{ formattedTotal }}</span>
        </div>

        <div v-if="pricing.cc_fees">
          <strong>Total (including paid card fees): </strong>
          <span>{{ formattedTotalWithCcFees }}</span>
        </div>
      </div>
    </SlideoutTrigger>
  </div>
</template>

<script>
import SlideoutTrigger from '@/domains/proposals/components/slideout-trigger';
import { toFixed10 } from '@/utils/formatting/math';
import { formatPrice } from '@/utils/formatting/pricing';

export default {
  name: 'ProposalTotals',

  components: {
    SlideoutTrigger,
  },

  props: {
    currency: {
      type: String,
      required: true,
    },
    decimalStructure:  {
      type: String,
      required: true,
    },
    pricing: {
      type: Object,
      required: true,
    },
    taxInclusive: Boolean,
    taxRates: {
      type: Array,
      required: true,
    },
  },

  computed: {
    formattedSubtotal: function() {
      return formatPrice(this.pricing.subtotal, this.decimalStructure, true, this.currency);
    },

    formattedTotal: function() {
      return formatPrice(this.pricing.total, this.decimalStructure, true, this.currency);
    },

    formattedTotalWithCcFees: function() {
      const totalWithFees = parseFloat(this.pricing.total) + this.pricing.cc_fees;
      return formatPrice(totalWithFees, this.decimalStructure, true, this.currency);
    },

    tax1: function() {
      const taxRate = this.getTaxRate();

      return {
        name: taxRate.name,
        value: taxRate.value,
        amount: this.pricing.tax,
        formattedAmount: formatPrice(this.pricing.tax, this.decimalStructure, true, this.currency),
      };
    },

    tax2: function() {
      const taxRate = this.getTaxRate(2);

      return {
        name: taxRate.name,
        value: taxRate.value,
        amount: this.pricing.tax2,
        formattedAmount: formatPrice(this.pricing.tax2, this.decimalStructure, true, this.currency),
      };
    },

    shouldShowTax2: function() {
      return this.pricing.show_both_rates || !!parseFloat(this.pricing.tax_rate_value2);
    },
  },

  methods: {
    /**
     * @param {Number} [rate]
     * @return {{name: string, value: number}}
     */
    getTaxRate: function(rate = 1) {
      const taxRates = this.taxRates;

      const idField = 'tax_rate_id' + (rate !== 1 ? rate : '');
      const valueField = 'tax_rate_value' + (rate !== 1 ? rate : '');

      let chosenRate = taxRates.find(taxRate => {
        if ( null === this.pricing[idField] || null === this.pricing[valueField] ) {
          return false;
        }

        const idMatches = taxRate.id * 1 === this.pricing[idField];
        const valueMatches = taxRate.value === parseFloat(toFixed10(this.pricing[valueField], 3));

        return idMatches && valueMatches;
      });

      if (chosenRate) {
        return chosenRate;
      }

      // Is the tax rate modified? (notated by the negative id)
      chosenRate = taxRates.find(taxRate => {
        if ( null === this.pricing[idField] || null === this.pricing[valueField] ) {
          return false;
        }

        const idMatches = taxRate.id * -1 === this.pricing[idField];
        const valueMatches = taxRate.value === parseFloat(toFixed10(this.pricing[valueField], 3));

        return idMatches && valueMatches;
      });

      if (chosenRate) {
        return chosenRate;
      }

      return {
        name: 'Tax',
        value: parseFloat(toFixed10(this.pricing.tax_rate_value, 3)),
      };
    },
  },
};
</script>

<style scoped>

</style>
