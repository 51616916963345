<template>
  <div
      :class="triggerClasses"
      @click="handleClick"
  >
    <slot></slot>

    <div v-if="title && false !== titlePosition" :class="titleClasses">
      {{title}}
    </div>
    <div v-if="action" class="slideout-trigger-label slideout-trigger-action">{{ action }}</div>
    
  </div>
</template>

<script>
export default {
  name: 'SlideoutTrigger',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    component: {
      type: String,
      required: true,
    },
    props: {
      type: Object,
      required: false,
      default: () => ({}),// an empty object
    },
    titlePosition: {
      type: [ String, Boolean ],
      required: false,
      default: 'tl',
    },
    saveButtonText: {
      type: String,
      required: false,
      default: 'Save',
    },
    noOutline: {
      type: Boolean,
      required: false,
      default: false,
    },
    action: {
      type: String,
    },
  },
  computed: {
    triggerClasses() {
      const store = this.$store.state.slideout;
      const classes = [ 'slideout-trigger' ];

      if (this.noOutline) {
        classes.push('no-outline');
      } else if (store.visible && store.component === this.component && store.props === this.props) {
        classes.push('slideout-visible');
      }

      return classes;
    },
    titleClasses() {
      return [
          'slideout-trigger-label',
          `slideout-trigger-position-${this.titlePosition}`,
      ];
    },
  },
  methods: {
    handleClick($event) {
      if (this.action && $event.target.className.includes('slideout-trigger-action')) {
        this.$emit('actionTaken');
        return;
      }
      this.$store.commit('setComponent', null);
      this.$store.commit('setProps', {});
      this.$nextTick(() => {
        this.$store.commit('setComponent', this.component);
        this.$store.commit('setProps', this.props);
        this.$store.commit('setTitle', this.title);
        this.$store.commit('setSubtitle', this.subtitle);
        this.$store.commit('setSaveBtnText', this.saveButtonText);
        this.$store.commit('setVisibility', true);
      });
    },
    onSave() {
      this.$emit('save');
    },
  },
};
</script>

<style lang="scss">
@import "../sass/variables";
$fontSize: 0.875rem;// 14px;
$labelVPosition: -1.125rem;// 20px

.slideout-trigger {
  position: relative;
  cursor: pointer;

  .slideout-trigger-label {
    display: none;
    position: absolute;
    color: $accent-color;
    font-size: $fontSize;
    font-weight: normal;
    text-decoration: none;
    line-height: $fontSize;
  }

  .slideout-trigger-position-tl {
    top: $labelVPosition;
    left: 0;
  }

  .slideout-trigger-position-tr {
    top: $labelVPosition;
    right: 0;
  }

  .slideout-trigger-position-bl {
    bottom: $labelVPosition;
    left: 0;
  }

  .slideout-trigger-position-br {
    bottom: $labelVPosition;
    right: 0;
  }

  &:not(.no-outline):hover {
    outline: $accent-color 2px dotted;

    .slideout-trigger-label {
      display: block;
    }
  }

  &.slideout-visible {
    display: block;
    outline-width: 3px;
    outline-style: solid;
  }

  .slideout-trigger-action {
    position: relative;
  }
}
</style>
