var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-overlay",
    {
      attrs: { opacity: ".8" },
      nativeOn: {
        click: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("h2", { staticClass: "title" }, [_vm._v("Useful Instructions!")]),
      _c(
        "v-toolbar",
        {
          staticClass: "floating-menu actions",
          attrs: { light: "", dense: "", rounded: "" }
        },
        [
          _vm._l(_vm.buttons, function(button, i) {
            return _c(
              "v-btn",
              {
                key: "instruction-button-" + button.id,
                attrs: { disabled: "", icon: "", color: "grey darken-3" }
              },
              [
                _c("v-icon", [_vm._v(_vm._s(button.icon))]),
                _c(
                  "span",
                  { staticClass: "tip", attrs: { "data-pos": i + 1 } },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(_vm._s(button.instruction || button.name))
                    ]),
                    _c("span", { staticClass: "arrow" })
                  ]
                )
              ],
              1
            )
          }),
          _c("v-divider", {
            staticClass: "mx-2",
            attrs: { vertical: "", inset: "" }
          }),
          _c(
            "v-btn",
            {
              attrs: {
                disabled: "",
                icon: "",
                color: "grey darken-3",
                "data-pos": "2"
              }
            },
            [
              _c("v-icon", [_vm._v("fa-ellipsis-v")]),
              _c(
                "span",
                {
                  staticClass: "tip",
                  attrs: { "data-pos": _vm.buttons.length + 1 }
                },
                [
                  _c("span", { staticClass: "label" }, [
                    _vm._v("Open menu for additional actions")
                  ]),
                  _c("span", { staticClass: "arrow" })
                ]
              )
            ],
            1
          )
        ],
        2
      ),
      _c("div", { staticClass: "center" }, [
        _c("span", { staticClass: "clicking", class: _vm.mobileClass }, [
          _c("span", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.clickLabel))
          ]),
          _c("span", { staticClass: "arrow" })
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "pa-5",
          class: _vm.mobileClass,
          attrs: { id: "btn-container" }
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "mb-5",
              attrs: { color: "primary", "x-large": "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v("Okay, Got it!")]
          ),
          _vm._v(
            " PS: Go to Menu > Instructions to bring up this screen again "
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }