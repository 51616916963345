var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.allProposalsLimitReached
        ? _c("v-alert", { attrs: { color: "red", type: "error" } }, [
            _vm._v(
              "You're at your proposal limit of " +
                _vm._s(_vm.allProposalsLimit) +
                " active proposals for the free plan. Archive proposals to create a new one."
            )
          ])
        : _vm._e(),
      !_vm.allProposalsLimitReached && _vm.proposalsLimitReached
        ? _c("v-alert", { attrs: { color: "red", type: "error" } }, [
            _vm._v(_vm._s(_vm.proposalsLimitMessage))
          ])
        : _vm._e(),
      !_vm.allProposalsLimitReached &&
      !_vm.proposalsLimitReached &&
      _vm.proposals80PercentUsed
        ? _c("v-alert", { attrs: { color: "red", type: "error" } }, [
            _vm._v(" You are close to your plan's annual proposal capacity."),
            _c("br"),
            _vm._v(
              " You have " +
                _vm._s(_vm.proposalsRemaining) +
                " " +
                _vm._s(
                  1 === _vm.proposalsRemaining ? "proposal" : "proposals"
                ) +
                " remaining."
            ),
            _c("br"),
            _vm._v(
              " Please reach out to our Curator Success team in chat to talk about options. "
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }