var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Container",
    [
      _c(
        "Row",
        { attrs: { "class-name": "details-head" } },
        [
          _c("Column", { attrs: { sizes: ["md-6"] } }, [
            _c("h1", { staticClass: "cu-container__label" }, [
              _vm._v(_vm._s(_vm.container.data.container_label))
            ])
          ]),
          _c(
            "Column",
            { attrs: { "class-name": "text-right", sizes: ["md-6"] } },
            [
              _c(
                "SlideoutTrigger",
                {
                  attrs: {
                    title: "Order Info",
                    "save-button-text": "Done",
                    props: {
                      dateFormat: _vm.proposal.company.date_format,
                      proposalDate: _vm.proposal.date,
                      proposalName: _vm.proposal.name
                    },
                    component: "name-date-editor"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.proposal.name) +
                      " - " +
                      _vm._s(_vm.weekday) +
                      " " +
                      _vm._s(_vm.date) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("hr"),
      _c(
        "div",
        { staticClass: "details-clients-vendors-wrap" },
        [
          _c(
            "Row",
            { attrs: { "class-name": "details-clients-wrap" } },
            [
              _c(
                "Column",
                {
                  attrs: { "class-name": "prepared-for-wrap", sizes: ["sm-8"] }
                },
                [
                  _c("label", { staticClass: "input-component__label" }, [
                    _vm._v(_vm._s(_vm.container.data.prepared_for_label))
                  ]),
                  _c(
                    "Row",
                    _vm._l(_vm.proposal.customers, function(customer, index) {
                      return _c(
                        "div",
                        {
                          key: customer.id,
                          class:
                            "cu-col cu-col--sm-6 prepared-for prepared-for--" +
                            index,
                          staticStyle: { "overflow-wrap": "break-word" }
                        },
                        [
                          _c(
                            "SlideoutTrigger",
                            {
                              attrs: {
                                title: "Edit Global Customer Info",
                                "title-position": "bl",
                                "save-button-text": "Done",
                                component: "contact-editor",
                                props: { index: index }
                              }
                            },
                            [
                              _c("Contact", {
                                attrs: {
                                  address: customer.address,
                                  address2: customer.address2,
                                  "company-name":
                                    customer.customer_company_name,
                                  email: customer.email,
                                  name: customer.full_name,
                                  phone: customer.phone,
                                  type: "customer"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              ),
              _c("Column", { attrs: { sizes: ["sm-4"] } }, [
                _c("label", { staticClass: "input-component__label" }, [
                  _vm._v(_vm._s(_vm.container.data.prepared_by_label))
                ]),
                _c(
                  "div",
                  { staticClass: "prepared-by" },
                  [
                    _c(
                      "SlideoutTrigger",
                      {
                        attrs: {
                          title: "Company",
                          "title-position": "bl",
                          "save-button-text": "Done",
                          component: "company-editor",
                          props: {
                            company: _vm.company,
                            owner_email: _vm.proposal.owner.user_email
                          }
                        }
                      },
                      [
                        _c("Contact", {
                          attrs: {
                            address: _vm.company.address,
                            address2: _vm.company.address2,
                            "company-name": _vm.company.name,
                            email: _vm.proposal.owner.user_email,
                            phone: _vm.company.phone,
                            website: _vm.company.website,
                            type: "company"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c("hr")
        ],
        1
      ),
      Boolean(_vm.proposal.event_note)
        ? _c(
            "div",
            { staticClass: "details-event-notes" },
            [
              _c(
                "SlideoutTrigger",
                {
                  attrs: {
                    title: "Event Notes",
                    "save-button-text": "Done",
                    component: "note-editor",
                    props: {
                      field: "event_note",
                      note: _vm.proposal.event_note
                    }
                  }
                },
                [
                  _c("RichTextOutput", {
                    attrs: { content: _vm.proposal.event_note }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }