<template>
  <v-navigation-drawer
    value="slideoutVisibility"
    fixed
    hide-overlay
    temporary
    width="600"
    stateless
    class="pb-4 object-slideout"
  >
    <v-list-item class="px-8 pt-8" :class="slideoutHeaderColor">
      <v-list-item-content>
        <v-list-item-title class="title" v-if="title">
          {{title}}
        </v-list-item-title>
        <v-list-item-subtitle v-if="subtitle">
          {{subtitle}}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-container class="px-8">
      <component
          v-if="component"
          v-bind="$store.state.slideout.props"
          :is="component"
          ref="theComponent"
          @complete="complete"
          @enterPress="maybeSaveOnEnterPress"
      />
    </v-container>

    <template v-slot:[actionsSlot]>
      <v-container>
        <v-row align="center">
          <v-col v-if="showDeleteBtn" cols="2">
            <v-icon
              class="ml-2"
              @click="onDelete"
            >
              fa-trash-o
            </v-icon>
          </v-col>
          <v-col>
            <v-btn color="grey lighten-3" class="text-primary" :class="`${btnClassPrefix}-cancel`" @click="onCancel" depressed block>
              Cancel Changes
            </v-btn>
          </v-col>
          <v-col>
            <v-btn :class="`${btnClassPrefix}-save`" color="primary" @click="onSave" depressed block :disabled="!saveBtnEnabled">
              {{btnSaveText}}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'Slideout',

  components: {
    AccountCreator: () => import('@/domains/proposals/components/editors/account-creator'),
    CompanyEditor: () => import('@/domains/proposals/components/editors/company-editor'),
    ContactEditor: () => import('@/domains/proposals/components/editors/contact-editor'),
    HeaderEditor: () => import('@/domains/proposals/components/editors/header-editor'),
    LineItemEditor: () => import('@/domains/proposals/components/editors/line-item-editor'),
    NameDateEditor: () => import('@/domains/proposals/components/editors/name-date-editor'),
    NoteEditor: () => import('@/domains/proposals/components/editors/note-editor'),
    SendProposalEmailInterface: () => import('@/domains/proposals/components/editors/send-proposal-email-interface'),
    SignupAndSend: () => import('@/domains/proposals/components/editors/signup-and-send'),
    TotalsEditor: () => import('@/domains/proposals/components/editors/totals-editor'),
  },

  data: () => ({
    showDeleteBtn: false,
    saveBtnEnabled: false,
    optimizely: undefined,
  }),

  computed: {
    slideoutVisibility: {
      get: function() {
        return this.$store.state.slideout.visible;
      },
      set: function(value) {
        this.$store.commit('setVisibility', value);
      },
    },
    component() {
      return this.$store.state.slideout.component;
    },
    btnSaveText() {
      return this.$store.state.slideout.btnSaveText;
    },
    title() {
      return this.$store.state.slideout.title;
    },
    subtitle() {
      return this.$store.state.slideout.subtitle;
    },
    actionsSlot() {
      return this.$vuetify.breakpoint.smAndDown ? 'prepend' : 'append';
    },
    btnClassPrefix() {
      return `action-slideout-${this.component}`;
    },
    userId() {
      return this.$store.state.auth?.user?.id;
    },
    slideoutHeaderColor() {
      if (!this.$optimizely.ready) return 'white';
      return this.$optimizely.getFeatureVariable('slideout_header', 'color');
    },
  },

  methods: {
    checkForDeleteHandler() {
      const callback = this.$refs.theComponent.onDelete;

      return typeof callback === 'function';
    },
    onSave() {
      const callback = this.$refs.theComponent.save;

      if (typeof callback === 'function') {
        callback(this.close);
      }
    },
    close() {
      this.$store.commit('setVisibility', false);
    },
    onCancel() {
      const cancelHandler = this.$refs.theComponent.onCancel;

      if (typeof cancelHandler === 'function') {
        cancelHandler();
      }

      this.close();
    },
    onDelete() {
      const callback = this.$refs.theComponent.onDelete;

      if (typeof callback === 'function') {
        callback(this.onCancel);
      }
    },
    complete(e) {
      this.$emit('complete', e);
    },
    maybeSaveOnEnterPress() {
      if (this.saveBtnEnabled) {
        this.onSave();
      }
    },
    watchChildProps() {
      if (this.$refs.theComponent) {
        this.$watch(() => {
          return this.$refs.theComponent.saveBtnEnabled;
        }, (saveBtnEnabled) => {
          this.saveBtnEnabled = saveBtnEnabled;
        });
        this.saveBtnEnabled = Boolean(this.$refs.theComponent.saveBtnEnabled);

        this.$watch(() => {
          return this.$refs.theComponent.showDeleteBtn;
        }, (showDeleteBtn) => {
          this.showDeleteBtn = showDeleteBtn;
        });
        this.showDeleteBtn = Boolean(this.$refs.theComponent.showDeleteBtn);
      }
    },
  },

  mounted() {
    this.watchChildProps();
  },

  updated() {
    this.watchChildProps();
  },
};
</script>
