<template>
  <v-overlay opacity=".8" @click.native="$emit('close')">
    <h2 class="title">Useful Instructions!</h2>
    <v-toolbar light dense rounded class="floating-menu actions">
      <v-btn
        v-for="(button, i) in buttons"
        :key="`instruction-button-${button.id}`"
        disabled
        icon
        color="grey darken-3"
      >
        <v-icon>{{ button.icon }}</v-icon>
        <span class="tip" :data-pos="i + 1">
          <span class="label">{{ button.instruction || button.name }}</span>
          <span class="arrow"></span>
        </span>
      </v-btn>

      <v-divider class="mx-2" vertical inset></v-divider>

      <v-btn disabled icon color="grey darken-3" data-pos="2">
        <v-icon>fa-ellipsis-v</v-icon>
        <span class="tip" :data-pos="buttons.length + 1">
          <span class="label">Open menu for additional actions</span>
          <span class="arrow"></span>
        </span>
      </v-btn>
    </v-toolbar>
    <div class="center">
      <span class="clicking" :class="mobileClass">
        <span class="label">{{ clickLabel }}</span>
        <span class="arrow"></span>
      </span>
    </div>
    <div id="btn-container" class="pa-5" :class="mobileClass">
      <v-btn class="mb-5" color="primary" x-large @click="$emit('close')">Okay, Got it!</v-btn>
      PS: Go to Menu > Instructions to bring up this screen again
    </div>
  </v-overlay>
</template>

<script>
export default {
  name: 'instructions',

  props: {
    buttons: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  computed: {
    mobileClass() {
      return this.$vuetify.breakpoint.smAndDown ? 'mobile' : 'desktop';
    },
    clickLabel() {
      return this.mobileClass === 'desktop' ? 'Click on an element to edit' : '(Tap on an element to edit)';
    },
  },

  methods: {
    setTipStyles() {
      let els = Array.from(document.getElementsByClassName('tip'));
      els.forEach(el => {
        const extra = 3 * parseInt(el.getAttribute('data-pos'));
        el.style.setProperty('--extra-spacing', `${extra}em`);
      });
    },
  },

  mounted() {
    this.setTipStyles();
  },
};
</script>

<style lang="scss" scoped>
h2.title {
  position: fixed;
  top: 5em;
  left: 2em;
}

#btn-container {
  position: fixed;
  bottom: 5em;
  right: 0;
  display: flex;
  flex-direction: column;
  &.desktop {
    align-items: flex-end;
  }
  &.mobile {
    width: 100%;
    align-items: center;
  }
}

.actions {
  .tip {
    --extra-spacing: 0em;
    $extraSpacing: var(--extra-spacing);

    color: white;
    position: absolute;
    font-weight: normal;
    bottom: calc(0em - #{$extraSpacing});
    right: 5em;

    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 0.35em;
      background: #fff;
    }

    &::before {
      width: 1px;
      left: calc(100% + 3.25em + 0em);
      height: calc(100% + #{$extraSpacing} - 3em);
    }

    &::after {
      height: 1px;
      left: calc(100% + 1.25em);
      width: 2em;
    }

    .arrow {
      width: 1em;
      height: 1em;
      display: block;
      position: absolute;
      bottom: calc(100% - 3.35em + #{$extraSpacing});
      left: calc(100% + 2.8em);

      &::before {
        content: '';
        display: block;
        width: 0.35em;
        height: 0.35em;
        margin-left: 0.175em;
        border-bottom: solid 0.35em #fff;
        border-left: solid 0.35em transparent;
        border-right: solid 0.35em transparent;
      }
    }
  }
}

.center {
  height: 100vh;
  width: 100vw;
  .clicking {
    position: absolute;
    top: 40%;

    .label {
      cursor: default;
      display: block;
      width: max-content;
    }

    &.desktop {
      left: calc(50% + 8vw);

      &::before {
        display: block;
        content: '';
        height: 1px;
        position: absolute;
        top: 1em;
        background: #fff;
        width: calc(18vw - 8em);
        right: calc(100% + 1em);
      }

      .arrow {
        width: 1em;
        height: 1em;
        display: block;
        position: absolute;
        top: 0.5em;
        transform: rotate(270deg);
        right: calc(100% + 18vw - 8em);

        &::before {
          content: '';
          display: block;
          width: 0.35em;
          height: 0.35em;
          margin-left: 0.175em;
          border-bottom: solid 0.35em #fff;
          border-left: solid 0.35em transparent;
          border-right: solid 0.35em transparent;
        }
      }
    }

    &.mobile {
      width: 100%;
      .label {
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>