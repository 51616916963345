<template>
  <v-dialog v-model="show" width="900" scrollable>
    <v-card>
      <v-card-title>Upgrade My Account</v-card-title>
      <v-card-text>
        <div class="meetings-iframe-container"
             data-src="https://we.curate.co/meetings/curate-meetings/small-business?embed=true"></div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'upgrade-account',
  data() {
    return {
      show: true,
    };
  },
  methods: {
    close() {
      this.show = false;
    },
  },
  mounted() {
    let meetingsScript = document.createElement('script');
    meetingsScript.setAttribute('src', 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js');
    document.head.appendChild(meetingsScript);
  },
  watch: {
    show(val) {
      if (!val) this.$emit('close');
    },
  },
};
</script>
