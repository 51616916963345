<template>
  <Container>
    <Row class-name="details-head">
      <Column :sizes="['md-6']">
        <h1 class="cu-container__label">{{ container.data.container_label }}</h1>
      </Column>
      <Column
        class-name="text-right"
        :sizes="['md-6']"
      >
        <SlideoutTrigger
          title="Order Info"
          save-button-text="Done"
          :props="{
            dateFormat: proposal.company.date_format,
            proposalDate: proposal.date,
            proposalName: proposal.name,
          }"
          component="name-date-editor"
        >
          {{ proposal.name }} - {{ weekday }} {{ date }}
        </SlideoutTrigger>
      </Column>
    </Row>
    <hr>
    <div class="details-clients-vendors-wrap">
      <Row class-name="details-clients-wrap">
        <Column class-name="prepared-for-wrap" :sizes="['sm-8']">
          <label class="input-component__label">{{ container.data.prepared_for_label }}</label>
          <Row>
            <div
                v-for="(customer, index) in proposal.customers"
                :key="customer.id"
                :class="'cu-col cu-col--sm-6 prepared-for prepared-for--' + index"
                style="overflow-wrap: break-word;"
            >
              <SlideoutTrigger
                title="Edit Global Customer Info"
                title-position="bl"
                save-button-text="Done"
                component="contact-editor"
                :props="{ index: index }"
              >
                <Contact
                  :address="customer.address"
                  :address2="customer.address2"
                  :company-name="customer.customer_company_name"
                  :email="customer.email"
                  :name="customer.full_name"
                  :phone="customer.phone"
                  type="customer"
                />
              </SlideoutTrigger>
            </div>
          </Row>
        </Column>
        <Column :sizes="['sm-4']">
          <label class="input-component__label">{{ container.data.prepared_by_label }}</label>
          <div class="prepared-by">
            <SlideoutTrigger
              title="Company"
              title-position="bl"
              save-button-text="Done"
              component="company-editor"
              :props="{ company: company, owner_email: proposal.owner.user_email }"
            >
              <Contact
                  :address="company.address"
                  :address2="company.address2"
                  :company-name="company.name"
                  :email="proposal.owner.user_email"
                  :phone="company.phone"
                  :website="company.website"
                  type="company"
              />
            </SlideoutTrigger>
          </div>
        </Column>
      </Row>
      <hr>
    </div>
    <div class="details-event-notes" v-if="Boolean(proposal.event_note)">
      <SlideoutTrigger
          title="Event Notes"
          save-button-text="Done"
          component="note-editor"
          :props="{ field: 'event_note', note: proposal.event_note }"
      >
        <RichTextOutput :content="proposal.event_note" />
      </SlideoutTrigger>
    </div>
  </Container>
</template>

<script>
// @ is an alias to /src
import Column from '@/domains/proposals/components/column';
import Contact from '@/domains/proposals/components/contact';
import Container from '@/domains/proposals/components/container.vue';
import Row from '@/domains/proposals/components/row.vue';
import RichTextOutput from '@/domains/proposals/components/rich-text-output.vue';
import { phpDateFormatToMoment, weekday } from '@/utils/formatting/dates';
import SlideoutTrigger from '@/domains/proposals/components/slideout-trigger';
import dayjs from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';

dayjs.extend(dayjsUTC);

export default {
  name: 'DetailsContainer',

  components: {
    SlideoutTrigger,
    Column,
    Row,
    Contact,
    Container,
    RichTextOutput,
  },

  props: {
    company: Object,
    container: Object,
    proposal: Object,
  },

  computed: {
    date() {
      return dayjs.utc(this.proposal.date).format(phpDateFormatToMoment(this.proposal.company.date_format));
    },

    weekday: function() {
      if (!this.company.weekdays) {
        return null;
      }

      return weekday(dayjs(this.proposal.date));
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../sass/mixins/grid-framework";
.container-details {
  hr {
    border-bottom: none;
  }

  .details-head {
    .cu-col {
      padding-bottom: 0;
      color: #8b8b8b;
      font-weight: bold;
    }

    .cu-container__label {
      margin-bottom: 0;
      border-bottom: none;
    }

    @include media-breakpoint-down(md) {
      .text-right {
        text-align: left;
      }
    }
  }

  .details-venues {
    &__venue {
      div {
        padding-left: 10px;

        &.details-venues__type {
          padding-left: 0;
        }
      }
    }
  }
}
</style>
