var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cu-container--proposal-totals" },
    [
      _c(
        "SlideoutTrigger",
        {
          attrs: {
            title: "Edit Total Info",
            "save-button-text": "Done",
            component: "totals-editor"
          }
        },
        [
          _c("div", { staticClass: "proposal-total-items" }, [
            !_vm.taxInclusive
              ? _c("div", [
                  _c("strong", [_vm._v("Items Subtotal: ")]),
                  _c("span", [_vm._v(_vm._s(_vm.formattedSubtotal))])
                ])
              : _vm._e(),
            !_vm.taxInclusive
              ? _c("div", [
                  _c("strong", [_vm._v(_vm._s(_vm.tax1.name) + ": ")]),
                  _c("span", [_vm._v(_vm._s(_vm.tax1.formattedAmount))])
                ])
              : _vm._e(),
            !_vm.taxInclusive && _vm.shouldShowTax2
              ? _c("div", [
                  _c("strong", [_vm._v(_vm._s(_vm.tax2.name) + ": ")]),
                  _c("span", [_vm._v(_vm._s(_vm.tax2.formattedAmount))])
                ])
              : _vm._e(),
            _c("div", [
              _c("strong", [_vm._v("Total: ")]),
              _c("span", [_vm._v(_vm._s(_vm.formattedTotal))])
            ]),
            _vm.pricing.cc_fees
              ? _c("div", [
                  _c("strong", [_vm._v("Total (including paid card fees): ")]),
                  _c("span", [_vm._v(_vm._s(_vm.formattedTotalWithCcFees))])
                ])
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }