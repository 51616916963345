import dayjs from 'dayjs';

export const weekdays = [ 'Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat' ];

export function phpDateFormatToMoment (format) {
  switch (format) {
    case 'm/d/Y':
      return 'MM/DD/YYYY';

    case 'mm/dd/yY':
      return 'MM/DD/YYYY';

    case 'm.d.Y':
      return 'MM.DD.YYYY';

    case 'mm.dd.yy':
      return 'MM.DD.YYYY';

    case 'd/m/Y':
      return 'DD/MM/YYYY';

    case 'dd/mm/yy':
      return 'DD/MM/YYYY';

    case 'd.m.Y':
      return 'DD.MM.YYYY';

    case 'dd.mm.yy':
      return 'DD.MM.YYYY';

    default:
      return 'MM/DD/YYYY';
  }
}

/**
 * @param {String} date
 * @param {String} format
 * @param {Boolean} [isMomentFormat]
 * @return {dayjs.Dayjs}
 */
export function stringToMomentDate(date, format, isMomentFormat = false) {
  let momentDateFormat = isMomentFormat ? format : phpDateFormatToMoment(format);
  return dayjs(
    date,
    momentDateFormat,
  );
}

/**
 * @param {moment.Moment} momentDate
 * @return {String}
 */
export function weekday(momentDate) {
  return weekdays[momentDate.day()];
}
