<template>
  <div :class="'cu-row' + (className ? ' ' + className : '')">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Row',

  props: {
    className: String,
  },
};
</script>

<style scoped>

</style>
