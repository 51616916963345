var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return 0 !== _vm.visibleItems.length
    ? _c(
        "Container",
        {
          attrs: {
            label: _vm.container.data.container_label,
            sublabel: _vm.expirationMessage
          }
        },
        [
          _c("LineItems", {
            attrs: {
              id: "line-items",
              "line-items": _vm.visibleItems,
              "hide-item-prices": Boolean(_vm.proposal.hide_item_prices),
              "hide-item-quantities": Boolean(
                _vm.proposal.hide_item_quantities
              ),
              currency: _vm.currency,
              "decimal-structure": _vm.decimalStructure
            }
          }),
          _c("Totals", {
            attrs: {
              currency: _vm.currency,
              "decimal-structure": _vm.decimalStructure,
              pricing: _vm.proposal.event_cost,
              "tax-inclusive": _vm.taxInclusive,
              "tax-rates": _vm.proposal.tax_rates
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }