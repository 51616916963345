var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "cu-container" },
    [
      _c(
        "SlideoutTrigger",
        {
          attrs: {
            title: "Header Image",
            "save-button-text": "Done",
            props: {
              currentImageUrl: _vm.imageSrc
            },
            component: "header-editor"
          }
        },
        [
          _c("v-img", {
            attrs: {
              "max-width": "1350",
              "max-height": "240",
              src: _vm.imageSrc
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }