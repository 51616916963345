<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Column',

  props: {
    className: String,
    sizes: Array,
  },

  computed: {
    classes: function() {
      const classes = [ 'cu-col' ];

      if ( this.sizes ) {
        for ( let i = 0; i < this.sizes.length; i ++ ) {
          classes.push( 'cu-col--' + this.sizes[i] );
        }
      }

      if ( this.className ) {
        classes.push( this.className );
      }

      return classes.join( ' ' );
    },
  },
};
</script>

<style scoped>

</style>
