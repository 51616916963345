<template>
  <Container
      v-if="0 !== visibleItems.length"
      :label="container.data.container_label"
      :sublabel="expirationMessage"
  >
    <LineItems
        id="line-items"
        :line-items="visibleItems"
        :hide-item-prices="Boolean(proposal.hide_item_prices)"
        :hide-item-quantities="Boolean(proposal.hide_item_quantities)"
        :currency="currency"
        :decimal-structure="decimalStructure"
    />

    <Totals
        :currency="currency"
        :decimal-structure="decimalStructure"
        :pricing="proposal.event_cost"
        :tax-inclusive="taxInclusive"
        :tax-rates="proposal.tax_rates"
    />
  </Container>
</template>

<script>
import Container from '@/domains/proposals/components/container.vue';
import Totals from '@/domains/proposals/components/totals';
import LineItems from '@/domains/proposals/components/line-items';

export default {
  name: 'ProposalContainer',

  components: {
    Container,
    Totals,
    LineItems,
  },

  props: {
    company: Object,
    container: Object,
    proposal: Object,
  },

  computed: {
    currency() {
      return this.company.currency ? this.company.currency : 'USD';
    },

    decimalStructure() {
      return this.company.decimal_structure ? this.company.decimal_structure : 'comma_dot';
    },

    expirationMessage: function() {
      return '' !== this.proposal.expiration_reason && this.proposal.expiration_date ? `(expires on ${ this.proposal.expiration_date })` : '';
    },

    taxInclusive: function() {
      return 'tax_inclusive_pricing' in this.company && this.company.tax_inclusive_pricing;
    },

    visibleItems: function() {
      return this.proposal.arrangements.filter((lineItem) => {
        return !parseInt(lineItem.addon, 10) && parseInt(lineItem.include, 10);
      });
    },

    possibilities: function() {
      return this.proposal.arrangements.filter((lineItem) => {
        return !!parseInt(lineItem.addon, 10) && !parseInt(lineItem.include, 10);
      });
    },
  },

  watch: {
    visibleItems (items) {
      if (items.length === 0) {
        this.$store.commit('addLineItem', { name: '(Add item here)', note: '(Add description here)' });
        this.$store.commit('recalculatePricing');
        this.$store.commit('setCurrentChanged', true);
      }
    },
  },
};
</script>

<style scoped>

</style>
