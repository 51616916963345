var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.triggerClasses, on: { click: _vm.handleClick } },
    [
      _vm._t("default"),
      _vm.title && false !== _vm.titlePosition
        ? _c("div", { class: _vm.titleClasses }, [
            _vm._v(" " + _vm._s(_vm.title) + " ")
          ])
        : _vm._e(),
      _vm.action
        ? _c(
            "div",
            { staticClass: "slideout-trigger-label slideout-trigger-action" },
            [_vm._v(_vm._s(_vm.action))]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }