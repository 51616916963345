<template>
  <v-toolbar dense rounded class="floating-menu object-floating-menu">
    <v-tooltip
      v-for="button in buttons"
      :key="`${button.id}-floating-button`"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :class="button.class"
          icon
          v-bind="attrs"
          v-on="on"
          color="grey darken-3"
          :disabled="button.disabled"
          @click="button.action"
        >
          <v-badge
            v-if="button.indicator"
            dot
            left
            offset-x="8"
            offset-y="8"
          >
            <v-icon>{{ button.icon }}</v-icon>
          </v-badge>
          <v-icon v-else>{{ button.icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ button.name }}</span>
    </v-tooltip>

    <v-divider v-if="showDivider" class="mx-2" vertical inset></v-divider>

    <v-menu left bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="hasMenu"
          class="action-floating-menu-ellipsis"
          icon
          color="grey darken-3"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>fa-ellipsis-v</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="item in menu"
          :key="`${item.name}-floating-menu-item`"
          :class="item.class"
          @click="item.action"
        >
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-toolbar>
</template>

<script>
export default {
  name: 'floating-actions',

  props: {
    buttons: {
      type: Array,
      default() {
        return [];
      },
    },
    menu: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  computed: {
    hasButtons() {
      return this.buttons && this.buttons.length > 0;
    },
    hasMenu() {
      return this.menu && this.menu.length > 0;
    },
    showDivider() {
      return this.hasButtons && this.hasMenu;
    },
  },
};
</script>
