<template>
  <div class="contact">
    <div class="contact__line contact__line--company-name" v-if="Boolean(companyName)">{{ companyName }}</div>
    <div class="contact__line contact__line--name" v-if="Boolean(name)">{{ name }}</div>
    <div class="contact__line contact__line--address" v-if="Boolean(address)">{{ address }}</div>
    <div class="contact__line contact__line--address2" v-if="Boolean(address2)">{{ address2 }}</div>
    <div class="contact__line contact__line--phone" v-if="Boolean(phone)">{{ phone }}</div>
    <div class="contact__line contact__line--website" v-if="Boolean(website)"><a :href="websiteWithHttp">{{ website }}</a></div>
    <a :href="'mailto:' + email" v-if="Boolean(email)">{{ email }}</a>
    <div class="contact__line contact__line--no-data" v-if="!hasData">
      {{ noDataMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',

  props: {
    companyName: String,
    name: String,
    address: String,
    address2: String,
    phone: String,
    website: String,
    email: String,
    type: String,
  },

  computed: {
    hasData() {
      const hasCommonPropertyData = Boolean(this.companyName)
        || Boolean(this.address)
        || Boolean(this.address2)
        || Boolean(this.phone)
        || Boolean(this.email);

      if (this.isCompanyType) {
        return hasCommonPropertyData || Boolean(this.website);
      }

      return hasCommonPropertyData || Boolean(this.name);
    },

    isCompanyType() {
      return this.type === 'company';
    },

    noDataMessage() {
      return this.isCompanyType
        ? 'Add Company Info Here'
        : 'Add Customer Here';
    },

    websiteWithHttp: function() {
      if ( ! this.website ) {
        return '';
      }

      return 0 !== this.website.toLowerCase().indexOf('http')
              ? 'http://' + this.website
              : this.website;
    },
  },
};
</script>

<style scoped>
.contact__line--no-data {
  color: var(--v-primary-base);
  text-decoration: underline;
}
</style>
