<template>
  <v-app-bar
    class="app-bar object-app-bar"
    fixed
    color="primary"
    elevation="5"
  >
    <a href="http://www.curate.co"><img class="app-bar__logo app-bar__logo--curate action-app-bar-logo" src="@/assets/curate-logo-text.svg" style="width: 80px;filter: brightness(100);" /></a>

    <v-spacer/>
    <a v-if="!user" class="white--text action-app-bar-sign-in" @click="login">Sign In</a>
    <v-menu
      class="app-bar__select"
      left
      offset-y
      v-if="user"
      max-height="400px"
      :close-on-content-click="false"
      v-model="showMenu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :loading="loading"
          v-on="on"
          v-bind="attrs"
          class="mr-6 action-app-bar-proposal-dropdown"
          color="grey lighten-4"
          width=20%
          depressed
        >
          Proposals
          <v-icon class="ml-2">fa-angle-down</v-icon>
        </v-btn>
      </template>
      <v-list
        style="cursor: pointer"
        max-height="400px"
        class="overflow-y-auto"
        color="grey lighten-4"
      >
        <v-list-item>
          <v-text-field
            label="Search"
            v-model="search"
            append-icon="fa-search"
            outlined
            dense
          />
        </v-list-item>
        <template v-for="event in filteredEvents">
          <v-list-item
            :key="`${event.id}-item`"
            @click="() => loadProposal(event.id)"
            class="text-truncate action-proposal-dropdown-option"
          >
            <v-list-item-title>{{ getDisplayText(event.name, event.date) }}</v-list-item-title>
          </v-list-item>
          <v-divider :key="`${event.id}-divider`" class="mx-4"/>
        </template>
        <v-list-item>
          <v-btn class="action-proposal-dropdown-create-button" color="primary" width="100%" to="/proposals/new">Add New Proposal</v-btn>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu
      class="app-bar__user-menu object-app-bar-user-menu"
      offset-y
      v-if="user"
      max-height="400px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          class="mr-1 action-user-menu-toggle"
          icon
          x-large
        >
          <v-avatar>
            <v-img
              v-if="companyLogoUrl"
              aspect-ratio="1"
              class="app-bar__logo app-bar__logo--company"
              :src="companyLogoUrl"
            />
            <v-icon v-else dark>
              fa-user-circle
            </v-icon>
          </v-avatar>
        </v-btn>
      </template>
      <v-list
        style="cursor: pointer"
        max-height="400px"
      >
        <v-list-item class="action-app-bar-edit-profile" @click="goToUserProfile">
          <v-list-item-title>Edit Profile</v-list-item-title>
        </v-list-item>
        <v-list-item class="action-app-bar-company-settings" @click="openCompanySlideout">
          <v-list-item-title>Company Settings</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-btn
            class="action-app-bar-logout"
            @click="logout"
            color="red"
            text
            small
          >
            Logout
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>

  </v-app-bar>
</template>

<script>
import { phpDateFormatToMoment } from '@/utils/formatting/dates';
import EVENTS_FOR_SELECT from '@/graphql/Events/EventsForSelect.gql';
import dayjs from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';

dayjs.extend(dayjsUTC);

export default {
  name: 'appbar',
  data: () => ({
    loading: true,
    search: '',
    showMenu: false,
    skipQuery: true,
  }),

  apollo: {
    events: {
      query: EVENTS_FOR_SELECT,
      variables() {
        return {
          page: -1,
          pageSize: -1,
          isOrder: false,
        };
      },
      update( res ) {
        this.loading = false;
        return res.events;
      },
      skip() {
        return this.skipQuery;
      },
    },
  },

  mounted() {
    this.fetchEventsIfUserLoggedIn();
    this.loading = false;
  },

  computed: {
    companyLogoUrl() {
      return this.$store.state.auth.company.logo[0] || '';
    },

    dateFormat() {
      return this.$store.state.proposals.current.company.date_format;
    },

    filteredEvents() {
      if (!this.events) {
        return [];
      }

      return this.events.filter(({ date, name }) => {
        const dateString = dayjs.utc(date).format(phpDateFormatToMoment(this.dateFormat));

        const dateContainsSearch = dateString.toLowerCase().includes( this.search.toLowerCase() );
        const nameContainsSearch = name.toLowerCase().includes( this.search.toLowerCase() );

        return dateContainsSearch || nameContainsSearch;
      });
    },

    user() {
      return this.$store.state.auth.user;
    },
    company() {
      return this.$store.state.auth.company;
    },
  },

  methods: {
    async fetchEventsIfUserLoggedIn() {
      if (this.user) {
        this.$apollo.queries.events.skip = false;
        await this.$apollo.queries.events.refetch();
        this.$apollo.queries.events.skip = true;
      }
    },

    getDisplayDate(date) {
      return dayjs.utc(date).format(phpDateFormatToMoment(this.dateFormat));
    },

    getDisplayText(name, date) {
      return `${name ? name : '(No Name)'} - ${this.getDisplayDate(date)}`;
    },

    goToUserProfile() {
      window.location.href = `${process.env.VUE_APP_CU_APP_URL}profile`;
    },

    loadProposal(id) {
      if (this.$store.state.proposals.currentChanged) {
        if ( !window.confirm('Do you want to discard your unsaved changes?') ) {
          return;
        }

        this.$store.commit('setCurrentChanged', false);
      }

      this.showMenu = false;
      this.$router.push({ name: 'Proposal', params: { id } });
    },
    login() {
      localStorage.removeItem('curate_token');
      const auth_url = process.env.VUE_APP_AUTH_LOGIN_URL;
      window.location.href = `${auth_url}?redirect_to=${encodeURIComponent(window.location.href)}`;
    },
    logout() {
      const logoutUrl = `${process.env.VUE_APP_CU_APP_URL}c/__logout?redirect_to=https://we.curate.co/blog`;

      this.$store.commit('auth/setUser', null);
      localStorage.removeItem('curate_token');
      window.location.href = logoutUrl;
    },
    openCompanySlideout() {
      this.$store.dispatch('open', {
        title: 'Edit Company Settings',
        component: 'company-editor',
        props: { company: this.company, owner_email: this.user.email, updateCompanySettings: true },
      });
    },
  },
};
</script>

<style scoped>
.app-bar__logo {
  width: 40px;
}

.app-bar__logo--company {
  overflow: hidden;
}
</style>
