var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticClass: "app-bar object-app-bar",
      attrs: { fixed: "", color: "primary", elevation: "5" }
    },
    [
      _c("a", { attrs: { href: "http://www.curate.co" } }, [
        _c("img", {
          staticClass:
            "app-bar__logo app-bar__logo--curate action-app-bar-logo",
          staticStyle: { width: "80px", filter: "brightness(100)" },
          attrs: { src: require("@/assets/curate-logo-text.svg") }
        })
      ]),
      _c("v-spacer"),
      !_vm.user
        ? _c(
            "a",
            {
              staticClass: "white--text action-app-bar-sign-in",
              on: { click: _vm.login }
            },
            [_vm._v("Sign In")]
          )
        : _vm._e(),
      _vm.user
        ? _c(
            "v-menu",
            {
              staticClass: "app-bar__select",
              attrs: {
                left: "",
                "offset-y": "",
                "max-height": "400px",
                "close-on-content-click": false
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass:
                                  "mr-6 action-app-bar-proposal-dropdown",
                                attrs: {
                                  loading: _vm.loading,
                                  color: "grey lighten-4",
                                  width: "20%",
                                  depressed: ""
                                }
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _vm._v(" Proposals "),
                            _c("v-icon", { staticClass: "ml-2" }, [
                              _vm._v("fa-angle-down")
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2840410049
              ),
              model: {
                value: _vm.showMenu,
                callback: function($$v) {
                  _vm.showMenu = $$v
                },
                expression: "showMenu"
              }
            },
            [
              _c(
                "v-list",
                {
                  staticClass: "overflow-y-auto",
                  staticStyle: { cursor: "pointer" },
                  attrs: { "max-height": "400px", color: "grey lighten-4" }
                },
                [
                  _c(
                    "v-list-item",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Search",
                          "append-icon": "fa-search",
                          outlined: "",
                          dense: ""
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._l(_vm.filteredEvents, function(event) {
                    return [
                      _c(
                        "v-list-item",
                        {
                          key: event.id + "-item",
                          staticClass:
                            "text-truncate action-proposal-dropdown-option",
                          on: {
                            click: function() {
                              return _vm.loadProposal(event.id)
                            }
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(_vm.getDisplayText(event.name, event.date))
                            )
                          ])
                        ],
                        1
                      ),
                      _c("v-divider", {
                        key: event.id + "-divider",
                        staticClass: "mx-4"
                      })
                    ]
                  }),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "action-proposal-dropdown-create-button",
                          attrs: {
                            color: "primary",
                            width: "100%",
                            to: "/proposals/new"
                          }
                        },
                        [_vm._v("Add New Proposal")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm.user
        ? _c(
            "v-menu",
            {
              staticClass: "app-bar__user-menu object-app-bar-user-menu",
              attrs: { "offset-y": "", "max-height": "400px" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "mr-1 action-user-menu-toggle",
                                attrs: { icon: "", "x-large": "" }
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c(
                              "v-avatar",
                              [
                                _vm.companyLogoUrl
                                  ? _c("v-img", {
                                      staticClass:
                                        "app-bar__logo app-bar__logo--company",
                                      attrs: {
                                        "aspect-ratio": "1",
                                        src: _vm.companyLogoUrl
                                      }
                                    })
                                  : _c("v-icon", { attrs: { dark: "" } }, [
                                      _vm._v(" fa-user-circle ")
                                    ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                54525905
              )
            },
            [
              _c(
                "v-list",
                {
                  staticStyle: { cursor: "pointer" },
                  attrs: { "max-height": "400px" }
                },
                [
                  _c(
                    "v-list-item",
                    {
                      staticClass: "action-app-bar-edit-profile",
                      on: { click: _vm.goToUserProfile }
                    },
                    [_c("v-list-item-title", [_vm._v("Edit Profile")])],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      staticClass: "action-app-bar-company-settings",
                      on: { click: _vm.openCompanySlideout }
                    },
                    [_c("v-list-item-title", [_vm._v("Company Settings")])],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "action-app-bar-logout",
                          attrs: { color: "red", text: "", small: "" },
                          on: { click: _vm.logout }
                        },
                        [_vm._v(" Logout ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }