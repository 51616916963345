<template>
  <v-container class="cu-container">
    <SlideoutTrigger
      title="Header Image"
      save-button-text="Done"
      :props="{
        currentImageUrl: imageSrc,
      }"
      component="header-editor"
    >
      <v-img
        max-width="1350"
        max-height="240"
        :src="imageSrc"
      ></v-img>
    </SlideoutTrigger>
  </v-container>
</template>

<script>
import SlideoutTrigger from '@/domains/proposals/components/slideout-trigger';

export default {
  name: 'Header',
  components: {
    SlideoutTrigger,
  },
  props: {
    image: String,
  },
  computed: {
    imageSrc() {
      return this.image || this.placeholder;
    },
    placeholder() {
      return require('@/assets/placeholder-header.png');
    },
  },
};
</script>