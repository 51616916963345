import { toFixed10 } from '@/utils/formatting/math';

export const currencyList = [
      {
        value: 'AED',
        text: 'AED - United Arab Emirates Dirham',
        symbol: '\u062F.\u0625',
      },
      {
        value: 'AUD',
        text: 'AUD - Australian Dollar',
        symbol: String.fromCharCode(36),
      },
      {
        value: 'BHD',
        text: 'BHD - Bahraini Dinar',
        symbol: 'BHD',
      },
      {
        value: 'BZD',
        text: 'BZD - Belize Dollar',
        symbol: 'BZD',
      },
      {
        value: 'CAD',
        text: 'CAD - Canadian Dollar',
        symbol: String.fromCharCode(36),
      },
      {
        value: 'DOP',
        text: 'DOP - Dominican Peso',
        symbol: String.fromCharCode(36),
      },
      {
        value: 'EUR',
        text: 'EUR - Euro',
        symbol: String.fromCharCode(8364),
      },
      {
        value: 'GBP',
        text: 'GBP - British Pound',
        symbol: String.fromCharCode(163),
      },
      {
        value: 'ILS',
        text: 'ILS - Israeli Shekel',
        symbol: '\u20AA',
      },
      {
        value: 'MUR',
        text: 'MUR - Mauritian Rupee',
        symbol: '\u20A8',
      },
      {
        value: 'NZD',
        text: 'NZD - New Zealand Dollar',
        symbol: 'NZD',
      },
      {
        value: 'RON',
        text: 'RON - Romanian Leu',
        symbol: 'L',
      },
      {
        value: 'USD',
        text: 'USD - United States Dollar',
        symbol: String.fromCharCode(36),
      },
      {
        value: 'XCD',
        text: 'XCD - Eastern Caribbean Dollar',
        symbol: String.fromCharCode(36),
      },
      {
        value: 'ZAR',
        text: 'ZAR - South African rand',
        symbol: String.fromCharCode(82),
      },
    ];

export const currencies = currencyList.reduce((currenciesObj, currency) => {
  currenciesObj[currency.value] = currency.symbol;
  return currenciesObj;
}, {});

/**
 *
 * @param {String|Number} value
 * @param {String} decimalStructure
 * @param {Boolean} [addCurrency]
 * @param {String} [currencySymbol]
 * @param {Boolean} [isPercentage]
 * @param {Boolean} [negativeParenthesis]
 * @return {string}
 */
export function formatPrice (
  value, decimalStructure, addCurrency, currencySymbol, isPercentage,
  negativeParenthesis) {
  addCurrency = (typeof addCurrency === 'undefined') ? false : !!addCurrency;

  value = parseFloat(value);
  if (isNaN(value)) {
    value = 0;
  }

  const is_negative = 0 > value;
  value = toFixed10(value, 2).toString();

  value = separatePriceSequences(value);

  if (addCurrency) {
    currencySymbol = formatCurrency(currencySymbol);

    if (isPercentage) {
      value = value + '%';
    } else {
      value = currencySymbol + value;
    }
  }

  if (negativeParenthesis && is_negative) {
    value = '(' + value.replace('-', '') + ')';
  }

  return value;
}

/**
 * @param {String} currencySymbol
 * @return {String}
 */
export function formatCurrency (currencySymbol) {
  if (typeof currencySymbol === 'undefined') {
    currencySymbol = String.fromCharCode(36); //dollar sign
  } else {
    currencySymbol = currencies[currencySymbol];
  }
  return currencySymbol;
}

/**
 * @param {Number|String} num
 * @param {String} separator
 * @return {string}
 */
export function separatePriceSequences (num, separator) {
  let str = num.toString().split('.');
  const separators = [];
  if ('space_dot' === separator) {
    separators[0] = ' ';
    separators[1] = '.';
  } else if ('space_comma' === separator) {
    separators[0] = ' ';
    separators[1] = ',';
  } else if ('comma_dot' === separator) {
    separators[0] = ',';
    separators[1] = '.';
  } else {
    separators[0] = ',';
    separators[1] = '.';
  }

  if (str[0].length >= 4) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1' + separators[0]);
  }
  if (str[1] && str[1].length >= 4) {
    str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }
  return str.join(separators[1]);
}

export function maybeStripDecimals (value, decimals) {
  let val = String(value).split('.');
  decimals = decimals || 2;

  if (val.length > 1) {
    value = val[0] + '.' + val[1].substring(0, decimals);
  }

  return value;
}
