var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.className },
    [
      _c(
        "SlideoutTrigger",
        {
          attrs: {
            title: "Line Item",
            "title-position": "tl",
            "save-button-text": "Done",
            component: "line-item-editor",
            props: { lineItem: _vm.lineItem },
            action: "+ Add Line Item"
          },
          on: { actionTaken: _vm.addLineItem }
        },
        [
          _c(
            "div",
            { staticClass: "line-items__line-item" },
            [
              _c("div", { staticClass: "line-items__line-item-head" }, [
                _c("div", { staticClass: "line-items__line-item-title" }, [
                  _c(
                    "span",
                    { staticClass: "line-items__line-item-title-span" },
                    [_vm._v(_vm._s(_vm.lineItemTitle))]
                  )
                ]),
                !_vm.isCentered && !_vm.hideItemQuantities
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "line-items__line-item-qty line-items__col-1",
                        style: { width: this.columnWidths[0] + "px" }
                      },
                      [
                        _vm.shouldShowItemQuantities
                          ? _c("span", [
                              _c(
                                "span",
                                { staticClass: "line-items__col-label" },
                                [_vm._v("Qty: ")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.maybeStripDecimals(
                                      _vm.lineItem.quantity
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                !_vm.isCentered && !_vm.hideItemPrices
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "line-items__line-item-cost line-items__col-2",
                        style: { width: this.columnWidths[1] + "px" }
                      },
                      [
                        _vm.shouldShowItemPrice
                          ? _c("span", [
                              Boolean(_vm.lineItem.is_percentage)
                                ? _c(
                                    "span",
                                    { staticClass: "line-items__col-label" },
                                    [_vm._v(" Price : ")]
                                  )
                                : _vm._e(),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.lineItemCost) +
                                  " " +
                                  _vm._s(
                                    Boolean(_vm.lineItem.is_percentage)
                                      ? "%"
                                      : ""
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                !_vm.isCentered && !_vm.hideItemPrices
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "line-items__line-item-cost line-items__col-3",
                        style: { width: this.columnWidths[2] + "px" }
                      },
                      [
                        _vm.shouldShowPrice
                          ? _c("span", [
                              null === _vm.lineItem.header_type
                                ? _c(
                                    "span",
                                    { staticClass: "line-items__col-label" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            Boolean(_vm.lineItem.is_percentage)
                                              ? "Price: "
                                              : "Subtotal: "
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              null === _vm.lineItem.header_type
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatPrice(
                                            _vm.lineItemTotal,
                                            _vm.decimalStructure,
                                            true,
                                            this.currency
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ]),
              _vm.notes.length || _vm.hasPhotos
                ? _c(
                    "Row",
                    { attrs: { "class-name": _vm.contentClassName } },
                    [
                      _c("Column", { attrs: { sizes: ["sm-12"] } }, [
                        _c(
                          "div",
                          { staticClass: "line-items__line-item-description" },
                          _vm._l(_vm.notes, function(note, index) {
                            return _c("span", { key: index }, [
                              _vm._v(" " + _vm._s(note) + " "),
                              _c("br")
                            ])
                          }),
                          0
                        ),
                        _vm.hasPhotos
                          ? _c(
                              "div",
                              { staticClass: "line-items__line-item-photos" },
                              _vm._l(_vm.lineItem.photos, function(
                                image,
                                index
                              ) {
                                return _c("ImageComponent", {
                                  key: "photo-" + index + "-" + image.id,
                                  attrs: {
                                    image: image,
                                    "show-remove-button": false,
                                    "show-title": false,
                                    size: "thumbnail"
                                  }
                                })
                              }),
                              1
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }