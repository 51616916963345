<template>
  <div>
    <v-alert
        v-if="allProposalsLimitReached"
        color="red"
        type="error"
    >You're at your proposal limit of {{ allProposalsLimit }} active proposals for the free plan. Archive proposals to create a new one.</v-alert>
    <v-alert
        v-if="! allProposalsLimitReached && proposalsLimitReached"
        color="red"
        type="error"
    >{{ proposalsLimitMessage }}</v-alert>
    <v-alert
        v-if="! allProposalsLimitReached &&! proposalsLimitReached && proposals80PercentUsed"
        color="red"
        type="error"
    >
      You are close to your plan's annual proposal capacity.<br />
      You have {{ proposalsRemaining }} {{ 1 === proposalsRemaining ? 'proposal' : 'proposals' }} remaining.<br />
      Please reach out to our Curator Success team in chat to talk about options.
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'LimitsWarnings',

  computed: {
    allProposalsLimit() {
      return this.$store.state.auth.limits.all_proposals_limit;
    },
    allProposalsLimitReached() {
      return 1 <= this.$store.state.auth.limits.all_proposals_used;
    },
    proposalsLimitReached() {
      return 1 <= this.$store.state.auth.limits.total_proposals_used;
    },
    proposalsLimitMessage() {
      return this.$store.state.auth.limits.is_free ?
          'Your plan\'s monthly proposal capacity has been reached. Please reach out to our Sales team to talk about options.' :
          'Your plan\'s annual proposal capacity has been reached. Please reach out to our Curator Success team in chat to talk about options.';
    },
    proposals80PercentUsed() {
      return 0.8 <= this.$store.state.auth.limits.total_proposals_used;
    },
    proposalsRemaining() {
      return this.$store.state.auth.limits.proposals - this.$store.state.auth.limits.total_proposals;
    },
  },
};
</script>

<style scoped>

</style>
