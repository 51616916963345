var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact" }, [
    Boolean(_vm.companyName)
      ? _c(
          "div",
          { staticClass: "contact__line contact__line--company-name" },
          [_vm._v(_vm._s(_vm.companyName))]
        )
      : _vm._e(),
    Boolean(_vm.name)
      ? _c("div", { staticClass: "contact__line contact__line--name" }, [
          _vm._v(_vm._s(_vm.name))
        ])
      : _vm._e(),
    Boolean(_vm.address)
      ? _c("div", { staticClass: "contact__line contact__line--address" }, [
          _vm._v(_vm._s(_vm.address))
        ])
      : _vm._e(),
    Boolean(_vm.address2)
      ? _c("div", { staticClass: "contact__line contact__line--address2" }, [
          _vm._v(_vm._s(_vm.address2))
        ])
      : _vm._e(),
    Boolean(_vm.phone)
      ? _c("div", { staticClass: "contact__line contact__line--phone" }, [
          _vm._v(_vm._s(_vm.phone))
        ])
      : _vm._e(),
    Boolean(_vm.website)
      ? _c("div", { staticClass: "contact__line contact__line--website" }, [
          _c("a", { attrs: { href: _vm.websiteWithHttp } }, [
            _vm._v(_vm._s(_vm.website))
          ])
        ])
      : _vm._e(),
    Boolean(_vm.email)
      ? _c("a", { attrs: { href: "mailto:" + _vm.email } }, [
          _vm._v(_vm._s(_vm.email))
        ])
      : _vm._e(),
    !_vm.hasData
      ? _c("div", { staticClass: "contact__line contact__line--no-data" }, [
          _vm._v(" " + _vm._s(_vm.noDataMessage) + " ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }