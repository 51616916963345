import { render, staticRenderFns } from "./container.vue?vue&type=template&id=0425224f&"
import script from "./container.vue?vue&type=script&lang=js&"
export * from "./container.vue?vue&type=script&lang=js&"
import style0 from "./container.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/nickray/Projects/curate-web/web-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0425224f')) {
      api.createRecord('0425224f', component.options)
    } else {
      api.reload('0425224f', component.options)
    }
    module.hot.accept("./container.vue?vue&type=template&id=0425224f&", function () {
      api.rerender('0425224f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/proposals/components/container.vue"
export default component.exports