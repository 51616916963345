var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.wrapperClassName,
      attrs: {
        title: _vm.showTitle ? _vm.image.title : null,
        "data-full-image-url": _vm.inGallery ? _vm.image.url : null,
        "data-full-image-w": _vm.inGallery ? _vm.image.width : null,
        "data-full-image-h": _vm.inGallery ? _vm.image.height : null
      }
    },
    [
      _c("img", { attrs: { src: _vm.details.src, alt: _vm.image.alt } }),
      _vm.showTitle
        ? _c("div", { staticClass: "image__filename-wrap" }, [
            _c("span", [_vm._v(_vm._s(_vm.image.title))])
          ])
        : _vm._e(),
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showRemoveButton,
              expression: "showRemoveButton"
            }
          ],
          staticClass: "image__remove-button",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("imageDelete", _vm.image.url)
            }
          }
        },
        [_c("v-icon", [_vm._v("fa-times")])],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }