var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { class: _vm.className },
    [
      _vm.label || this.$slots.label
        ? _c(
            "h1",
            { staticClass: "cu-container__label" },
            [
              _vm._t("label", [_vm._v(_vm._s(_vm.label))]),
              _vm.sublabel || this.$slots.sublabel
                ? _c(
                    "span",
                    { staticClass: "cu-container__sublabel" },
                    [_vm._t("sublabel", [_vm._v(_vm._s(_vm.sublabel))])],
                    2
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }