var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "pb-4 object-slideout",
      attrs: {
        value: "slideoutVisibility",
        fixed: "",
        "hide-overlay": "",
        temporary: "",
        width: "600",
        stateless: ""
      },
      scopedSlots: _vm._u(
        [
          {
            key: _vm.actionsSlot,
            fn: function() {
              return [
                _c(
                  "v-container",
                  [
                    _c(
                      "v-row",
                      { attrs: { align: "center" } },
                      [
                        _vm.showDeleteBtn
                          ? _c(
                              "v-col",
                              { attrs: { cols: "2" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-2",
                                    on: { click: _vm.onDelete }
                                  },
                                  [_vm._v(" fa-trash-o ")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "text-primary",
                                class: _vm.btnClassPrefix + "-cancel",
                                attrs: {
                                  color: "grey lighten-3",
                                  depressed: "",
                                  block: ""
                                },
                                on: { click: _vm.onCancel }
                              },
                              [_vm._v(" Cancel Changes ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-btn",
                              {
                                class: _vm.btnClassPrefix + "-save",
                                attrs: {
                                  color: "primary",
                                  depressed: "",
                                  block: "",
                                  disabled: !_vm.saveBtnEnabled
                                },
                                on: { click: _vm.onSave }
                              },
                              [_vm._v(" " + _vm._s(_vm.btnSaveText) + " ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _c(
        "v-list-item",
        { staticClass: "px-8 pt-8", class: _vm.slideoutHeaderColor },
        [
          _c(
            "v-list-item-content",
            [
              _vm.title
                ? _c("v-list-item-title", { staticClass: "title" }, [
                    _vm._v(" " + _vm._s(_vm.title) + " ")
                  ])
                : _vm._e(),
              _vm.subtitle
                ? _c("v-list-item-subtitle", [
                    _vm._v(" " + _vm._s(_vm.subtitle) + " ")
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "px-8" },
        [
          _vm.component
            ? _c(
                _vm.component,
                _vm._b(
                  {
                    ref: "theComponent",
                    tag: "component",
                    on: {
                      complete: _vm.complete,
                      enterPress: _vm.maybeSaveOnEnterPress
                    }
                  },
                  "component",
                  _vm.$store.state.slideout.props,
                  false
                )
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }