<template>
  <div
      :class="wrapperClassName"
      :title="showTitle ? image.title : null"
      :data-full-image-url="inGallery ? image.url : null"
      :data-full-image-w="inGallery ? image.width : null"
      :data-full-image-h="inGallery ? image.height : null"
  >
    <img
        :src="details.src"
        :alt="image.alt"
    />

    <div class="image__filename-wrap" v-if="showTitle">
      <span>{{ image.title }}</span>
    </div>

    <button
      type="button"
      v-show="showRemoveButton"
      class="image__remove-button"
      @click="$emit('imageDelete', image.url)"
    >
      <v-icon>fa-times</v-icon>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ImageComponent',

  props: {
    image: {
      type: Object,
      required: true,
    },
    inGallery: {
      type: Boolean,
      default: true,
    },
    size: {
      type: [ String, Array ],
    },
    showRemoveButton: {
      type: Boolean,
      default: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    wrapperClassName: function() {
      let className = 'image';
      if ( this.image.width > this.image.height ) {
        className = `${ className } image--landscape`;
      } else {
        className = `${ className } image--portrait`;
      }

      return className;
    },

    details: function() {
      const image = this.image;
      const size = this.size;
      let width = image.width;
      let height = image.height;
      let src = image.url;

      if (Array.isArray(size)) {
        width = size[0];
        height = size[1];
      } else if ( size !== null ) {
        let imageSize;
        if ( 'full' === this.size || undefined === image[ this.size ] ) {
          imageSize = [
            image.url,
            image.width,
            image.height,
          ];
        } else {
          imageSize = image[this.size];
        }

        if ( ! imageSize ) {
          console.error('Image:', image);
          throw new Error(`Image size ${this.size} not available`);
        }

        src = imageSize[0];
        width = imageSize[1];
        height = imageSize[2];
      }

      return { width, height, src };
    },
  },
};
</script>

<style scoped lang="scss">
.image {
  display: inline-block;
  position: relative;
  margin: 0 14px 14px 0;

  img {
    object-fit: cover;
  }

  &__filename-wrap {
    display: none;
  }

  &__icon {
    object-fit: contain;
  }

  &__remove-button {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    justify-content: center;
    align-items: center;

    i {
      text-shadow: 0 0 1px #fff;
    }
  }

  @include media-breakpoint-up(lg) {
    &__remove-button {
      display: none;
    }

    &:hover {
      .image__remove-button {
        display: flex;
      }
    }
  }
}
</style>
