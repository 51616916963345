import { render, staticRenderFns } from "./rich-text-output.vue?vue&type=template&id=1831e852&"
import script from "./rich-text-output.vue?vue&type=script&lang=js&"
export * from "./rich-text-output.vue?vue&type=script&lang=js&"
import style0 from "./rich-text-output.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/nickray/Projects/curate-web/web-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1831e852')) {
      api.createRecord('1831e852', component.options)
    } else {
      api.reload('1831e852', component.options)
    }
    module.hot.accept("./rich-text-output.vue?vue&type=template&id=1831e852&", function () {
      api.rerender('1831e852', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/proposals/components/rich-text-output.vue"
export default component.exports