var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "text-center" },
          [
            _c("v-progress-circular", {
              attrs: { indeterminate: "", color: "primary" }
            })
          ],
          1
        )
      : _vm._e(),
    !_vm.loading
      ? _c(
          "div",
          { staticClass: "proposal" },
          [
            _c(
              "div",
              { class: _vm.wrapperClass },
              [
                _c("AppBar"),
                _c(
                  "div",
                  { class: _vm.contentClass },
                  [
                    _vm.isCreating ? _c("LimitsWarning") : _vm._e(),
                    _c("div", { staticClass: "proposal-tabs" }),
                    _c("Header", { attrs: { image: _vm.headerImage } }),
                    _vm._l(_vm.availableContainers, function(container) {
                      return _c(
                        "div",
                        {
                          key: container.id,
                          class: ["container-" + container.container_type]
                        },
                        [
                          _c(_vm.containerName(container.container_type), {
                            tag: "component",
                            attrs: {
                              container: container,
                              company: _vm.company,
                              proposal: _vm.proposal
                            }
                          })
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ],
              1
            ),
            _vm.$store.state.slideout.visible
              ? _c("Slideout", { on: { complete: _vm.completeSlideout } })
              : _vm._e(),
            _c("FloatingMenu", {
              attrs: { buttons: _vm.floatingButtons, menu: _vm.floatingMenu }
            }),
            _vm.showUpgrade
              ? _c("UpgradeAccount", {
                  on: { close: _vm.toggleUpgradeAccount }
                })
              : _vm._e(),
            _vm.showInstructions
              ? _c("Instructions", {
                  attrs: { buttons: _vm.floatingButtons },
                  on: { close: _vm.toggleInstructions }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }