var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "line-items__column-headers" }, [
      !_vm.hideItemQuantities
        ? _c(
            "div",
            {
              staticClass: "line-items__column-header line-items__col-1",
              style: { width: _vm.colWidths[0] + "px" }
            },
            [_vm._v(" " + _vm._s(_vm.shows.qty ? "Qty" : "") + " ")]
          )
        : _vm._e(),
      !_vm.hideItemPrices
        ? _c(
            "div",
            {
              staticClass: "line-items__column-header line-items__col-2",
              style: { width: _vm.colWidths[1] + "px" }
            },
            [_vm._v(" " + _vm._s(_vm.shows.price ? "Price" : "") + " ")]
          )
        : _vm._e(),
      !_vm.hideItemPrices
        ? _c(
            "div",
            {
              staticClass: "line-items__column-header line-items__col-3",
              style: { width: _vm.colWidths[2] + "px" }
            },
            [_vm._v(" " + _vm._s(_vm.shows.subtotal ? "Subtotal" : "") + " ")]
          )
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "cu-container--proposal-line_items" },
      _vm._l(_vm.lineItems, function(lineItem) {
        return _c("LineItem", {
          key: lineItem.id,
          attrs: {
            currency: _vm.currency,
            "decimal-structure": _vm.decimalStructure,
            "hide-item-quantities": _vm.hideItemQuantities,
            "hide-item-prices": _vm.hideItemQuantities,
            "line-item": lineItem,
            "line-items": _vm.lineItems,
            "column-widths": _vm.colWidths
          }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }