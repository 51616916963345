var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "900", scrollable: "" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Upgrade My Account")]),
          _c("v-card-text", [
            _c("div", {
              staticClass: "meetings-iframe-container",
              attrs: {
                "data-src":
                  "https://we.curate.co/meetings/curate-meetings/small-business?embed=true"
              }
            })
          ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("v-btn", { attrs: { text: "" }, on: { click: _vm.close } }, [
                _vm._v("Close")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }