import { render, staticRenderFns } from "./proposal.vue?vue&type=template&id=d99ce0c6&scoped=true&"
import script from "./proposal.vue?vue&type=script&lang=js&"
export * from "./proposal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d99ce0c6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/nickray/Projects/curate-web/web-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d99ce0c6')) {
      api.createRecord('d99ce0c6', component.options)
    } else {
      api.reload('d99ce0c6', component.options)
    }
    module.hot.accept("./proposal.vue?vue&type=template&id=d99ce0c6&scoped=true&", function () {
      api.rerender('d99ce0c6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/proposals/components/containers/proposal.vue"
export default component.exports