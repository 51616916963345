<template>
  <div :class="className">
    <SlideoutTrigger
      title="Line Item"
      title-position="tl"
      save-button-text="Done"
      component="line-item-editor"
      :props="{ lineItem: lineItem }"
      action="+ Add Line Item"
      @actionTaken="addLineItem"
    >
      <div class="line-items__line-item">
        <div class="line-items__line-item-head">
          <div class="line-items__line-item-title">
            <span class="line-items__line-item-title-span">{{
              lineItemTitle
            }}</span>
          </div>

          <div
            v-if="!isCentered && !hideItemQuantities"
            class="line-items__line-item-qty line-items__col-1"
            :style="{ width: this.columnWidths[0] + 'px' }"
          >
            <span v-if="shouldShowItemQuantities">
              <span class="line-items__col-label">Qty: </span>
              {{ maybeStripDecimals(lineItem.quantity) }}
            </span>
          </div>

          <div
            v-if="!isCentered && !hideItemPrices"
            class="line-items__line-item-cost line-items__col-2"
            :style="{ width: this.columnWidths[1] + 'px' }"
          >
            <span v-if="shouldShowItemPrice">
              <span
                v-if="Boolean(lineItem.is_percentage)"
                class="line-items__col-label"
              >
                Price :
              </span>
              {{ lineItemCost }}
              {{ Boolean(lineItem.is_percentage) ? "%" : "" }}
            </span>
          </div>

          <div
            v-if="!isCentered && !hideItemPrices"
            class="line-items__line-item-cost line-items__col-3"
            :style="{ width: this.columnWidths[2] + 'px' }"
          >
            <span v-if="shouldShowPrice">
              <span
                v-if="null === lineItem.header_type"
                class="line-items__col-label"
              >
                {{ Boolean(lineItem.is_percentage) ? "Price: " : "Subtotal: " }}
              </span>
              <span v-if="null === lineItem.header_type">
                {{
                  formatPrice(
                    lineItemTotal,
                    decimalStructure,
                    true,
                    this.currency
                  )
                }}
              </span>
            </span>
          </div>
        </div>
        <Row :class-name="contentClassName" v-if="notes.length || hasPhotos">
          <Column :sizes="['sm-12']">
            <div class="line-items__line-item-description">
              <span v-for="(note, index) in notes" :key="index">
                {{ note }}
                <br />
              </span>
            </div>
            <div class="line-items__line-item-photos" v-if="hasPhotos">
              <ImageComponent
                v-for="(image, index) in lineItem.photos"
                :key="'photo-' + index + '-' + image.id"
                :image="image"
                :show-remove-button="false"
                :show-title="false"
                size="thumbnail"
              />
            </div>
          </Column>
        </Row>
      </div>
    </SlideoutTrigger>
  </div>
</template>

<script>
import Row from '@/domains/proposals/components/row';
import Column from '@/domains/proposals/components/column';
import {
  getParent,
  getSectionConfig,
  getSectionLineItems,
  shouldShowItemPhotos,
  shouldShowItemPrice,
  shouldShowItemQuantities,
  shouldShowSectionPrice,
} from '@/domains/proposals/utils/line-items';
import ImageComponent from '@/domains/proposals/components/image';
import { formatPrice, maybeStripDecimals } from '@/utils/formatting/pricing';
import SlideoutTrigger from '@/domains/proposals/components/slideout-trigger';

export default {
  name: 'LineItem',

  components: {
    ImageComponent,
    Row,
    Column,
    SlideoutTrigger,
  },

  props: {
    lineItem: {
      type: Object,
      required: true,
    },
    lineItems: {
      type: Array,
      required: true,
    },
    decimalStructure: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    hideItemQuantities: {
      type: Boolean,
      default: false,
    },
    hideItemPrices: {
      type: Boolean,
      default: false,
    },
    columnWidths: {
      type: Array,
      required: true,
    },
  },

  computed: {
    sectionConfig: function() {
      return null !== this.lineItem.header_type
          ? getSectionConfig(this.lineItem.header_type)
          : null;
    },

    parentSectionConfig: function() {
      return this.parentSection
          ? getSectionConfig(this.parentSection.header_type)
          : null;
    },

    parentSection: function() {
      return getParent(this.lineItem, this.lineItems);
    },

    notes: function() {
      const trimmedNotes = this.lineItem.note.replace(/^\s*(.*?)\s*$/gs, '$1');
      if ('' === trimmedNotes) {
        return [];
      }
      return trimmedNotes.split('\n');
    },

    isCentered: function() {
      const parentSection = this.parentSection;

      return (
          'centered_items' === this.lineItem.header_type ||
          (parentSection && 'centered_items' === parentSection.header_type)
      );
    },

    className: function() {
      const classes = [ 'line-items__line-item-wrapper' ];
      // if sectionConfig is defined then this is a section header
      const sectionConfig = this.sectionConfig;
      const parentSectionConfig = this.parentSectionConfig;

      if (sectionConfig && 'no_section' !== sectionConfig.key) {
        classes.push('line-items__line-item-wrapper--is-section');
        classes.push(
            'line-items__line-item-wrapper--section-' + sectionConfig.key,
        );
      } else if (parentSectionConfig) {
        classes.push('line-items__line-item-wrapper--in-section');
        classes.push(
            'line-items__line-item-wrapper--section-' + parentSectionConfig.key,
        );
      }

      if (0 === this.lineItem.photos?.length) {
        classes.push('line-items__line-item-wrapper--no-photos');
      }

      return classes.join(' ');
    },

    hasPhotos: function() {
      return (
          shouldShowItemPhotos(this.lineItem, this.lineItems) &&
          /* @TODO implement permission checking maybe? */
          // curate.AC.has("proposal.item.photos") &&
          this.lineItem.photos?.length > 0
      );
    },

    contentClassName: function() {
      let classes = [ 'line-items__line-item-content' ];

      if (this.hasPhotos) {
        classes.push('line-items__line-item-content--has-photos');
      }

      return classes.join(' ');
    },

    shouldShowPrice: function() {
      const lineItem = this.lineItem;

      const isItemAndShouldShowPrice =
          null === lineItem.header_type &&
          shouldShowItemPrice(lineItem, this.parentSection);
      const isSectionAndShouldShowPrice =
          null !== lineItem.header_type && shouldShowSectionPrice(lineItem);

      return isItemAndShouldShowPrice || isSectionAndShouldShowPrice;
    },

    shouldShowItemQuantities: function() {
      return shouldShowItemQuantities(this.lineItem, this.parentSection);
    },

    shouldShowItemPrice: function() {
      return shouldShowItemPrice(this.lineItem, this.parentSection);
    },

    lineItemCost: function() {
      let lineItemCost = this.lineItem.override_cost;
      lineItemCost = !this.lineItem.is_percentage
          ? formatPrice(lineItemCost, this.decimalStructure, true, this.currency)
          : lineItemCost;

      return lineItemCost;
    },

    lineItemTotal: function() {
      const lineItem = this.lineItem;
      let subtotal = lineItem.override_cost * lineItem.quantity;
      if (null !== lineItem.header_type) {
        const sectionLineItems = getSectionLineItems(lineItem, this.lineItems);
        subtotal = sectionLineItems.reduce((total, item) => {
          total += item.override_cost * item.quantity;
          return total;
        }, 0);
      }

      return subtotal;
    },

    lineItemTitle: function() {
      const lineItem = this.lineItem;
      let label = lineItem.name;

      if (null !== lineItem.header_type && this.shouldShowPrice) {
        label +=
            ' - ' +
            formatPrice(
                this.lineItemTotal,
                this.decimalStructure,
                true,
                this.currency,
            );
      }

      return label;
    },
  },

  methods: {
    formatPrice,
    maybeStripDecimals,
    addLineItem() {
      this.$store.dispatch('open', {
        title: 'Add Line Item',
        component: 'line-item-editor',
        saveBtnText: 'Add',
        props: { lineItem: null },
      });
    },
  },
};
</script>

<style scoped></style>
