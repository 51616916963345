<template>
  <div>
    <div class="line-items__column-headers">
      <div
          v-if="! hideItemQuantities"
          class="line-items__column-header line-items__col-1"
          :style="{width: colWidths[0] + 'px'}"
      >
        {{ shows.qty ? "Qty" : "" }}
      </div>
      <div
          v-if="! hideItemPrices"
          class="line-items__column-header line-items__col-2"
          :style="{width: colWidths[1] + 'px'}"
      >
        {{ shows.price ? "Price" : "" }}
      </div>
      <div
          v-if="! hideItemPrices"
          class="line-items__column-header line-items__col-3"
          :style="{width: colWidths[2] + 'px'}"
      >
        {{ shows.subtotal ? "Subtotal" : "" }}
      </div>
    </div>
    <div class="cu-container--proposal-line_items">
      <LineItem
          v-for="lineItem in lineItems"
          :key="lineItem.id"
          :currency="currency"
          :decimal-structure="decimalStructure"
          :hide-item-quantities="hideItemQuantities"
          :hide-item-prices="hideItemQuantities"
          :line-item="lineItem"
          :line-items="lineItems"
          :column-widths="colWidths"
      />
    </div>
  </div>
</template>

<script>
import { getSectionConfig, shouldShowItemPrice, shouldShowItemQuantities } from '@/domains/proposals/utils/line-items';
import LineItem from '@/domains/proposals/components/line-item';
import _debounce from 'lodash-es/debounce';

export default {
  name: 'LineItems',

  components: {
    LineItem,
  },

  props: {
    lineItems: {
      type: Array,
      required: true,
    },
    id: String,
    decimalStructure:  {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    hideItemQuantities: {
      type: Boolean,
      default: false,
    },
    hideItemPrices: {
      type: Boolean,
      default: false,
    },
  },

  data: () => {
    return {
      colWidths: [ 0,0,0 ],
    };
  },

  mounted() {
    window.addEventListener('resize', _debounce(this.calculateLineItemColumnWidths, 250) );
    this.$nextTick(this.calculateLineItemColumnWidths);
  },

  beforeDestroy() {
    window.removeEventListener('resize', _debounce(this.calculateLineItemColumnWidths, 250) );
  },

  methods: {
    calculateLineItemColumnWidths() {
      this.calculateLineItemColumnWidth(0);
      this.calculateLineItemColumnWidth(1);
      this.calculateLineItemColumnWidth(2);
    },

    calculateLineItemColumnWidth(columnIndex) {
      this.$set(this.colWidths, columnIndex, 0);
      this.$nextTick(() => {
        const elements = this.$el.querySelectorAll(`.line-items__col-${columnIndex + 1}`);
        let maxWidth = 0;

        for (const el of elements) {
          const elWidth = el.scrollWidth;

          if (maxWidth < elWidth) {
            maxWidth = elWidth;
          }
        }

        this.$set(this.colWidths, columnIndex, maxWidth);
      }, 100);
    },
  },

  computed: {
    shows: function() {
      const shows = {
        qty: false,
        price: false,
        subtotal: false,
      };

      let prevSection = null;
      for (const lineItem of this.lineItems) {
        if (shows.qty && shows.price && shows.subtotal) {
          break;
        }

        if (null !== lineItem.header_type) {
          prevSection = lineItem;
          continue;
        }

        let shouldShowItem = false;
        const sectionConfig = null !== prevSection ? getSectionConfig(prevSection.header_type) : false;
        if (sectionConfig && sectionConfig.showItem && 1 === parseInt(lineItem.include, 10)) {
          shouldShowItem = true;
        }

        if (!shouldShowItem) {
          continue;
        }

        // item is not a section and is not in a section
        if (prevSection === null) {
          shows.qty = true;
          shows.price = true;
          shows.subtotal = true;

          break;
        }

        if (shouldShowItemQuantities(lineItem, prevSection)) {
          shows.qty = true;
        }

        if (shouldShowItemPrice(lineItem, prevSection)) {
          shows.price = true;
          shows.subtotal = true;
        }
      }

      return shows;
    },
  },
};
</script>

<style lang="scss">
@import "../sass/variables";
@import "../sass/themes/sprout/variables";
@import "../sass/mixins/grid-framework";
@import "../sass/mixins/misc";

.line-items {
  &__line-item-wrapper {
    margin-top: 0;

    &:first-of-type {
      margin-top: 0;
    }

    &--section-centered_items {
      text-align: center;
    }

    &.being-dragged {
      .line-items__line-item {
        border-top: 4px dashed #dedede !important;
        border-bottom: 4px dashed #dedede !important;
      }
    }
  }

  &__line-item-title {
    margin-right: 8px;
  }

  &__line-item-content {
    width: 100%;
    margin-top: 20px;

    &__collapsed {
      display: none;
    }
  }

  &__line-item-photos {
    margin-top: 10px;

    &:after,
    &:before {
      content: '';
      display: table;
      clear: both;
    }

    &--empty {
      display: block;
      width: 100%;
      height: 100px;
      background-color: #F6F6FB;
      color: $accent-color;
      padding: 10px;

      @include media-breakpoint-up(md) {
        height: 200px;
        padding: 20px;
      }

      &:hover,
      &:focus {
        color: darken($accent-color, 20%);
      }
    }

    &__placeholder {
      border: 2px dashed $accent-color;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  &__separator {
    margin-bottom: 60px;
  }

  &__line-item {
    transition: all 0.2s ease-in-out !important;

    &--draft {}
    &--expanded {}
    &--parent {}
    &--section {}
  }

  &__line-item-head--content {
    display: flex;
    justify-content: space-between;
  }

  &__line-item-pricing {
    display: inline;
    float: right;

    &--error {
      background-color: #e08080;
      border-radius: 10px;
      padding-right: 8px;
    }

    &__error {
      padding: 0 8px 5px;
    }
  }

  &__line-item-title-input {
    // float: right;
    display: inline;
    margin-left: 8px;
    font-size: rem-calc(17px);

    &--percentage input {
      text-align: right;
    }
  }

  &__actions {
    display: inline-block;
  }

  &__actions-wrapper {}

  &__add-dropdown {
    z-index: 10;
  }
}

.line-items {

  &__line-item-wrapper {
    border-bottom: 1px solid #cfcfcf;
    padding-top: 10px;
    margin-top: 0;

    &:first-child {
      border-top: 1px solid #cfcfcf;
    }

    &--is-section {
      padding: 5px 13px;
      font-size: rem-calc(17px);
      background-color: #F0F0F0;

      .line-items__line-item-title {
        max-width: 100%;
      }
    }

    &--in-section {
      @include media-breakpoint-up(md) {
        padding-left: 80px;
      }
    }
  }

  &__line-item {

    &--draft {}
    &--expanded {}
    &--parent {}
    &--section {}
  }

  &__actions {
    position: relative;
    line-height: 20px;
  }

  &__line-item-head {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
    }
  }

  &__line-item-title {
    display: block;
    color: black;
    flex-grow: 1;
    margin-right: 0;

    .input-component__string-input {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      background: #fff;
    }
  }

  &__line-item-wrapper {
    padding-bottom: 10px;

    &--section-centered_items {
      padding-left: 0;
      padding-right: 0;

      .line-items__line-item-title {
        float: none;

        @include media-breakpoint-up(md) {
          max-width: 100%;
        }
      }
    }
  }

  &__line-item-cost,
  &__line-item-qty {
    width: 100%;
    text-align: right;
    font-size: rem-calc(14px);
    margin-left: 30px;
    color: #767676;

  }

  &__line-item-photos {
    margin-top: 0;

    .image {
      margin: 5px;
      border: none;
      box-shadow: none;
      border-radius: 6px;
      overflow: hidden;

      > img {
        width: 75px;
        height: 75px;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__line-item-description {
    font-size: rem-calc(15px);
    color: #767676;
  }

  &__line-item-content > .cu-col--sm-12 {
    padding-bottom: 10px;
  }

  &__line-item-title {}
  &__line-item-title--section {
    font-size: rem-calc(30px);
  }


  &__column-headers {
    display: flex;
    justify-content: flex-end;
  }

  &__col-1,
  &__col-2,
  &__col-3 {
    flex-grow: 0;

    @include media-breakpoint-down(sm) {
      @media screen {
        width: 100% !important;
      }
    }

    @include media-breakpoint-up(md) {
      margin-left: 2rem;
      text-align: right;
      padding-right: 0;
    }
  }

  &__col-3 {
    min-width: 70px;
  }

  &__column-header {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &__col-label {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}
</style>
