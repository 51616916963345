<template>
  <section :class="className">
    <h1 class="cu-container__label" v-if="label || this.$slots.label">
      <slot name="label">{{ label }}</slot>

      <span class="cu-container__sublabel" v-if="sublabel || this.$slots.sublabel">
        <slot name="sublabel">{{ sublabel }}</slot>
      </span>
    </h1>
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: 'Container',

  props: {
    label: String,
    sublabel: String,
    variants: Array,
  },

  computed: {
    className: function() {
      let classes = 'cu-container';

      if (this.variants) {
        let variants = this.variants;

        classes = variants.reduce((classes, variant) => {
          classes += ` cu-container--variant-${variant}`;
          return classes;
        }, classes);
      }

      return classes;
    },
  },
};
</script>

<style lang="scss">
@import "../sass/themes/sprout/variables";
@import "../sass/mixins/misc";

.theme-default {
  .cu-container {
    background-color: $container-background;
    border-radius: $container-border-radius;
    border: 1px solid $border-color;
    max-width: 1170px;
    margin: 25px auto 50px auto;
    padding: 20px;

    @include media-breakpoint-up(sm) {
      padding: 50px;
    }

    &--proposal-line_items {
      padding: 0;
    }

    &--proposal-totals {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;
      padding: 0;
    }

    .proposal-total-items {
      > div:last-child {
        font-weight: bold;
      }
    }

    &--variant-no-bg {
      background-color: transparent;
      border: none;
    }
  }

  .cu-container__label {
    font-family: var(--title-font, 'PTSans-Caption'), sans-serif !important;
    //font-family: "Cinzel Decorative", sans-serif;
    font-size: rem-calc(16px);
    font-weight: 700;
    margin-bottom: 30px;
    color: #8b8b8b;
    border-bottom: 1px solid $border-color;
  }

  .cu-container__sublabel {
    font-style: italic;
  }

  .section-hr {
    border-top: 3px solid #5381F6;
    border-bottom: none;
    border-left: none;
    border-right: none;
    opacity: 0.1;
  }
}
</style>
